import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import HackerMindSvg from "../images/undraw/undraw_hacker_mind_6y85.svg";
import LogoCloud from "../components/LogoCloud";

const RubyOnRailsLegacy = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Ruby on Rails Consulting and Development" />
          <Card
            visible={cardVisible}
            header="Ruby on Rails Consulting and Development"
            previousUrl="/ruby-on-rails-legacy"
            /* nextUrl="/ruby-on-rails-new-2" */
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Sometimes I’m consulted with refactoring a legacy RoR application
              that I’ve never seen before. That’s when an informed use of
              certain <strong>code metrics</strong> can come in very handy:
            </p>
            <div className="grid gap-6 grid-cols-1">
              <div className="flex">
                <FontAwesomeIcon
                  icon={faChartNetwork}
                  size="2x"
                  className="text-magenta-900"
                />
                <div className="ml-5">
                  <h2 className="text-left text-gray-700 leading-6 text-lg mb-4">
                    Unused Code:
                  </h2>
                  <p className="text-base leading-6 text-gray-700 text-left mb-3">
                    Quite often, legacy Ruby on Rails applications come with a
                    fairly large amount of unused code. While it may be tempting
                    to think about this as ballast to be deleted, that is rarely
                    advisable. Most of the time, abandoned code tells something
                    about the path of history a project took, so it’s a primary
                    location to look at, evaluate and decide what and how to
                    transform, rebuild or delete.
                  </p>
                </div>
              </div>
              <figure className="w-full">
                <img alt="hacker mind" src={HackerMindSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "bullet-train",
                url: "https://bullettrain.co/",
                type: "png",
              },
              {
                name: "code-and-co",
                url: "https://www.codeandco.com/",
                type: "svg",
              },
              {
                name: "inter-pool",
                url: "https://www.inter-pool.at",
              },
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default RubyOnRailsLegacy;
